import { Outlet } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import "./Visitor.css";
import Header from "./Header";
import New_Header from "./New_Header";
const Layout = () => {
    return (
        <main className="App">
            {/* <Header/> */}
            <New_Header/>
            <Outlet />
            {/* <ToastContainer /> */}
        </main>
    );
};

export default Layout;
