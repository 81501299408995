import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
  CardTitle,
} from "react-bootstrap";
import Service_man from "../../img/Service_man.png";
import "./Web_Dev.css";

const Web_Dev = () => {
  const navigate = useNavigate();
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (file) => {
    setSelectedFileName(file ? file.name : "");
  };

  const handleInputChange = (e) => {
    setSelectedFileName(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setSelectedFileName("");
    }
  };
  const fileInputRef = useRef(null);

  const handleCardClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (e) => {
    const files = e.target.files;
    console.log(files);
  };
  return (
    <>
      <Container className="main-cont-srvs">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10} className="main-cont-srvs-col">
            <div className="divspace" />
            <Breadcrumb>
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
                className="breadcrumb-active3"
                onClick={() => navigate("/service")}
              >
                Services
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active">
                Web Development
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />

            <h3 className="heading-bold2">
              Empower Your Business with Exceptional Web Development Expertise
            </h3>
            <br />
            <p className="para-bold2">
              Join forces with ScriptCode, The perfectionist in web development
              services, as we help you create, innovate, and expand your digital
              presence. Our comprehensive offerings encompass design,
              development, scaling, integration, and maintenance, ensuring your
              software products stand out and deliver exceptional results.
            </p>
            <br />
            <button varient="" className="button2">
              Request a free quote
            </button>
            <br />
            <br />
            <img
              src={Service_man}
              alt="service_man"
              className="service-man-img"
            />
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
      <Container className="cont-wd">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <p className="paragraph-grid-bold">
              Game-changing web development services
            </p>
            <p className="paragraph-grid">
              Professional web development, based on brand new technologies and
              supportive services, provides a diversity of solutions tailored to
              your business processes, challenges, and requirements.
            </p>

            <CardGroup className="cardgroup-f">
              <Row>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-f">
                    <Card.Body>
                      <Card.Title className="card-title4">
                        Front-end development
                      </Card.Title>
                      <Card.Text className="card-txt4">
                        ScriptCode front- end developers create websites and web
                        apps with feature-rich and functional interfaces. We use
                        reliable technology stack and frameworks.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-f">
                    <Card.Body>
                      <Card.Title className="card-title4">
                        Back-end development
                      </Card.Title>
                      <Card.Text className="card-txt4">
                        Our back-end developers focus on databases, scripting,
                        API, and website architecture.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-f">
                    <Card.Body>
                      <Card.Title className="card-title4">
                        UI/UX design
                      </Card.Title>
                      <Card.Text className="card-txt4">
                        We enable the user-centric approach to the interfaces.
                        Our UX/UI designers create accessible, bright, and
                        goal-focused interface designs.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-f">
                    <Card.Body>
                      <Card.Title className="card-title4">
                        SEO services
                      </Card.Title>
                      <Card.Text className="card-txt4">
                        ScriptCode web developers together with SEO-specialists
                        build SEO-ready websites technically perfect for search
                        engine crawling.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br />
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
      <Container className="cont-gd">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <p className="paragraph2">
              We are always at your disposal if you want something extra in Web
              Development
            </p>
            <div className="div6">
              <Row>
                <Col xs={12} md={9}>
                  <p className="paragraph3">
                    ScriptCode sees its mission in working respectfully and
                    honestly along with customers on software products to serve
                    the business needs. We provide fully customizable web
                    development services, tailored in compliance with your
                    demand.
                  </p>
                </Col>
                <Col xs={12} md={3} />
              </Row>
            </div>
            <CardGroup className="cardgroup-f">
              <Row>
                <Col xs={12} md={4} className="card-g-col">
                  <Card className="card-g">
                    <Card.Body>
                      <Card.Title className="card-title5">
                        CRM and ERP development
                      </Card.Title>
                      <Card.Text className="card-txt5">
                        With the custom in-house systems, you’ll enable
                        automation in processes and procedures analytics and
                        provide improvements in inventory management, human
                        resources, procurements management and processing,
                        manufacturing, financial accounting, billing and payment
                        administration and realization, customer relationship
                        management, etc.
                      </Card.Text>
                    </Card.Body>
                  </Card>{" "}
                  <br />
                </Col>
                <Col xs={12} md={4} className="card-g-col">
                  <Card className="card-g">
                    <Card.Body>
                      <Card.Title className="card-title5">
                        Machine Learning
                      </Card.Title>
                      <Card.Text className="card-txt5">
                        Machine Learning algorithms and intelligent data-driven
                        applications are game-changers for improving the
                        businesses of every size and scale. They enable the
                        automation of working processes and flows and address
                        the challenges that are complex and large-scale.
                      </Card.Text>
                    </Card.Body>
                  </Card>{" "}
                  <br />
                </Col>
                <Col xs={12} md={4} className="card-g-col">
                  <Card className="card-g">
                    <Card.Body>
                      <Card.Title className="card-title5">
                        SaaS development
                      </Card.Title>
                      <Card.Text className="card-txt5">
                        From scratch, we build SaaS that stand out in their
                        quality and are competitive in the market. From the
                        market research and the product concept, we lead you to
                        a web product that is loved by your customers.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br /> <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
      <Container className="cont-bl">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="div6">
              <Row>
                <Col xs={12} md={9}>
                  <h5 className="paragraph4-heading">
                    We meet industry-specific challenges
                  </h5>
                  <p className="paragraph4">
                    Web development services by ScriptCode are the catalyst for
                    business improvements within different industries. As a web
                    development company, we’re constantly adjusting our services
                    to the markets’ realities and technology trends. Our
                    contribution to customers’ digital development in such
                    business spheres as real estate, ecommerce, healthcare,
                    media, education, and fintech is recognized and valued by
                    our customers.
                  </p>
                </Col>
                <Col xs={12} md={3} />
              </Row>
            </div>
            <CardGroup className="cardgroup-f">
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <Card className="card-h">
                    <Card.Body>
                      <Card.Text className="card-txt6">
                        eLearning Apps
                        <div className="div-ct6" />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="card-i">
                    <Card.Body>
                      <Card.Text className="card-txt6">Healthcare</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="card-h">
                    <Card.Body>
                      <Card.Text className="card-txt6">Real Estate</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="card-i">
                    <Card.Body>
                      <Card.Text className="card-txt6">Sport</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br />
            <br />
            <p className="paragraph5">
              Enjoy the rewards of working with our professionals
            </p>
            <p className="paragraph6">
              A web development company is crucial for driving your business's
              success in a highly competitive market. Skilled web development
              unlocks myriad advantages, such as effortlessly embracing the
              latest technologies and tailoring them to your distinct needs.
            </p>
            <br />
            <CardGroup className="card-gp1">
              <Row>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-j">
                    <Card.Body>
                      <Card.Title className="card-title6">1</Card.Title>
                      <Card.Text className="card-txt7">
                        We save your time and efforts
                      </Card.Text>
                      <Card.Text className="card-txt8">
                        Many of our customers admit the ease of doing business
                        with us. Proficient web development team works on your
                        project fast and proactively.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-j">
                    <Card.Body>
                      <Card.Title className="card-title6">2</Card.Title>
                      <Card.Text className="card-txt7">
                        We justify expectations
                      </Card.Text>
                      <Card.Text className="card-txt8">
                        Our web development team delivers innovative solutions
                        capable of providing results for business improvements.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-j">
                    <Card.Body>
                      <Card.Title className="card-title6">3</Card.Title>
                      <Card.Text className="card-txt7">
                        We showcase your business original style
                      </Card.Text>
                      <Card.Text className="card-txt8">
                        We ensure with UX/UI design the authentic company's
                        identity and transfer ideas into the performances and
                        functionality.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                  <Card className="card-j">
                    <Card.Body>
                      <Card.Title className="card-title6">4</Card.Title>
                      <Card.Text className="card-txt7">
                        We do our utmost for you
                      </Card.Text>
                      <Card.Text className="card-txt8">
                        We put commitment and interest in creating a valuable
                        web solution for you. ScriptCode's dedicated team is
                        well-organized, highly proficient, and effective.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br />
            <br />
            <br />
            {/* <p className="paragraph7">MAY LATTER WE PUT SOME FAQ’S OR BLOG CONTENT HERE</p> */}
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <p className="paragraph8">Have Questions?</p>
                  <p className="paragraph9">
                    We have put together a list of FAQs about our web app
                    development workflow answered by our professionals.
                  </p> <br/>
                </Col>
                <Col xs={12} md={6} />
              </Row>
            </div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What are web development services ?
                </Accordion.Header>
                <Accordion.Body>
                  Web development services encompass a broad range of activities
                  involved in creating, building, and maintaining websites and
                  web applications. These services include web design, where the
                  visual aspects and user interface are crafted; front-end
                  development, which focuses on building the parts of a website
                  that users interact with using HTML, CSS, and JavaScript; and
                  back-end development, which involves creating the server-side
                  logic, databases, and application functionality that make a
                  website run smoothly. Additionally, web development services
                  often cover areas like e-commerce development, content
                  management systems (CMS) integration, search engine
                  optimization (SEO), and website maintenance and updates to
                  ensure optimal performance, security, and user experience.
                  These services are essential for businesses and individuals
                  looking to establish a robust online presence and engage
                  effectively with their target audience.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What is custom website development?
                </Accordion.Header>
                <Accordion.Body>
                  Custom website development involves creating a unique,
                  tailor-made website designed specifically to meet the
                  individual needs and preferences of a business or client.
                  Unlike template-based solutions, custom development starts
                  from scratch, allowing for complete flexibility in design,
                  functionality, and features. This process typically includes
                  thorough planning and consultation to understand the client's
                  goals, custom coding to implement specific functionalities,
                  and a design that reflects the brand's identity. Custom
                  websites are built to be scalable, secure, and optimized for
                  performance, ensuring they can grow with the business and
                  provide a superior user experience. This approach is ideal for
                  businesses with unique requirements or those seeking to stand
                  out with a distinctive online presence.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How much does it cost to build a great website ?
                </Accordion.Header>
                <Accordion.Body>
                  The cost of building a great website can vary widely depending
                  on several factors, including the complexity of the design,
                  the features and functionalities required, the platform used,
                  and the experience level of the developers. Here's a rough
                  breakdown: 1. Basic Website: A simple website with minimal
                  features, such as a personal blog or small business site,
                  typically costs between $1,000 and $5,000. This includes basic
                  design, a few pages, and standard functionalities. 2. Small to
                  Medium Business Website: For a more robust website with custom
                  design, multiple pages, and advanced functionalities like
                  e-commerce capabilities, interactive elements, and content
                  management systems, the cost ranges from $5,000 to $20,000. 3.
                  Large and Complex Website: Enterprise-level websites with
                  extensive custom features, high-end security, integrated
                  databases, and advanced e-commerce or membership
                  functionalities can cost between $20,000 and $100,000 or more.
                  These projects often require ongoing maintenance and
                  development, adding to the overall cost. 4. Additional Costs:
                  Beyond development, consider additional expenses such as
                  domain registration, hosting, ongoing maintenance, SEO
                  services, and digital marketing, which can add several hundred
                  to a few thousand dollars annually. Overall, the cost is
                  highly dependent on the specific needs and goals of the
                  website, as well as the chosen development path and the
                  expertise of the developers involved. Many businesses prefer
                  developing an MVP and adding features with time, as MVP
                  product requires less investment and is easily scalable as the
                  business grows.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col xs={12} md={1} />
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default Web_Dev;
