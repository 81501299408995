import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  DropdownButton,
  Button,
  Container,
  Nav,
  Navbar,
  Dropdown,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import SC_logo_lb from "../../src/img/SC_logo_lb.png";
import SC_logo_RB from "../../src/img/SC_logo_RB.png";
import "./Header.css";

function Header() {
  const expand = "md";
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("/");

  const headerMenuClick = (menu) => {
    setActiveLink(menu);
    navigate(menu);
  };


  return (
    <>
      <Navbar
        sticky="top"
        key={expand}
        expand={expand}
        className=" mb-3 header-cont-bg"
      >
        <Container fluid className="header-height">
          <Row>
            <Col xs={2} md={2}>
              <Navbar.Brand href="#">
                <img src={SC_logo_RB} alt="logo" className="logo"></img>
              </Navbar.Brand>
            </Col>
            <Col xs={8} md={8}></Col>
            <Col xs={2} md={2} >
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="sidebar-header"
              />
            </Col>
          </Row>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            className="sidebar-body nav-ofcn"
          >
            <Offcanvas.Header
              closeButton
              className="sidebar-body-header"
            >
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${expand}`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className="justify-content-end flex-grow-1 pe-3 d-none d-md-flex  nav-top nav-left ">
                <Nav.Link
                  className="navtext"
                  // {activeLink === "/" ? "active-header" : ""}
                  onClick={() => headerMenuClick("/")}
                >
                  About ScriptCode
                </Nav.Link>
                <Nav.Link
                  className="navtext2"
                  // {activeLink === "/about-us" ? "active-header" : ""}
                  onClick={() => headerMenuClick("/service")}
                >
                  Service
                </Nav.Link>
                <Nav.Link
                  className="navtext3"
                  // {
                  //   activeLink === "/doctors-profile" ? "active-header" : ""
                  // }
                  onClick={() => headerMenuClick("/resources")}
                >
                  Resources
                </Nav.Link>
                <Nav.Link
                  className="navtext4"
                  // {
                  //   activeLink === "/department" ? "active-header" : ""
                  // }
                  onClick={() => headerMenuClick()}
                >
                  Industries
                </Nav.Link>
                <Nav.Link
                  className="navtext5"
                  // {
                  //   activeLink === "/facilities" ? "active-header" : ""
                  // }
                  onClick={() => headerMenuClick()}
                >
                  Portfolio
                </Nav.Link>
                <Button variant="" size="sm" className="button-header">
                  Get Estimate
                </Button>{" "}
              </Nav>

              {/* Mobile Header Menu */}
              <div className="d-block d-md-none overlay pt-2">
                <Nav.Link
                  className="margin-10"
                  style={{ color: "#30B3A9" }}
                  onClick={() => navigate("/")}
                >
                  <i
                    class="bi bi-file-person-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  About ScriptCode
                </Nav.Link>
                <hr />
                <Nav.Link
                  className="margin-10"
                  onClick={() => navigate("/service")}
                >
                  <i
                    class="bi bi-pc-display-horizontal"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Service
                </Nav.Link>
                <hr />
                <Nav.Link
                  href="#action2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => navigate("/resources")}
                >
                  <i
                    class="bi bi-houses-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Resources
                </Nav.Link>
                <hr />
                <Nav.Link
                  href="#action2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => navigate()}
                >
                  <i
                    class="bi bi-pc-display-horizontal"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Industries
                </Nav.Link>
                <hr />
                <Nav.Link
                  href="#action2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => navigate()}
                >
                  <i
                    class="bi bi-heart-pulse-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Portfolio
                </Nav.Link>
                <hr />                
                <br />
                <br />
                <br />
                <br />

                <p
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    marginRight: "0px",
                  }}
                >
                  support@scriptcode.in
                </p>
                <br />
                <a
                  href=""
                  target="_blank"
                  className="link-sm"
                >
                  <i
                    class="bi bi-facebook"
                    style={{
                      marginLeft: "10px",
                      marginRight: "8px",
                      marginTop: "20px",
                    }}
                  ></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  className="link-sm"
                >
                  <i
                    class="bi bi-twitter-x"
                    style={{ marginLeft: "8px", marginTop: "20px" }}
                  ></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  className="link-sm"
                >
                  <i
                    class="bi bi-linkedin"
                    style={{ marginLeft: "14px", marginTop: "20px" }}
                  ></i>
                </a>
                <a
                  href=""
                  target="_blank"
                  className="link-sm"
                >
                  <i
                    class="bi bi-instagram"
                    style={{ marginLeft: "14px", marginTop: "20px" }}
                  ></i>
                </a>
                <br />
                <br />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <br /> <hr />
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
