import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
  CardTitle,
  CardText,
} from "react-bootstrap";
import "./Web_Dev.css";
import "./Service.css";
import Technology_Experties from "../about-us/Technology_Experties";

const Service = () => {
  const navigate = useNavigate();
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (file) => {
    setSelectedFileName(file ? file.name : "");
  };

  const handleInputChange = (e) => {
    setSelectedFileName(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setSelectedFileName("");
    }
  };
  const fileInputRef = useRef(null);

  const handleCardClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (e) => {
    const files = e.target.files;
    console.log(files);
  };
  return (
    <>
      <Container
      className="service-1st-cont">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="divspace" />
            <Breadcrumb className="service-bm">
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active3">
                Services
              </Breadcrumb.Item>
            </Breadcrumb>
            <br /> <br/>
            
                  <h3 className="heading-bold2">
                    Empower Your Business with Exceptional Web Development
                    Expertise
                  </h3>
                  <br />
                  <p className="para-bold2-service-1stcont">
                    Join forces with ScriptCode, The perfectionist in web
                    development services, as we help you create, innovate, and
                    expand your digital presence. Our comprehensive offerings
                    encompass design, development, scaling, integration, and
                    maintenance, ensuring your software products stand out and
                    deliver exceptional results.
                  </p>
                  <br />
                  <button varient="" className="button2">
                    Request a free quote
                  </button>
            <br/> 
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>

      <Container className="service-2nd-cont">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <p className="service-2nd-cont-bold">
              We provide an extensive array of technology services designed to
              satisfy the particular requirements of businesses in a variety of
              sectors. Our knowledge ranges from web development solutions to
              custom software development, enabling our clients to keep ahead of
              the constantly changing digital landscape. View our offerings
              below.
            </p>
            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main">
                    <CardBody>
                      <CardTitle className="csm-title">
                        Custom Software Development
                      </CardTitle>
                      <CardText className="csm-text">
                        As a software development service provider, Custom
                        software development refers to the creation of online,
                        mobile, and corporate applications that are specifically
                        designed to satisfy certain business needs.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card
                    className="card-service-main2"
                    onClick={() => navigate("/web-dev")}
                  >
                    <CardBody>
                      <CardTitle className="csm-title">
                        Web Development
                      </CardTitle>
                      <CardText className="csm-text">
                        At ScriptCode, we specialize in delivering top-notch web
                        development services tailored to your unique needs. With
                        a passion for innovation and a commitment to excellence,
                        we craft dynamic websites that captivate audiences and
                        drive results. Let us bring your digital vision to life
                        with our expertise in coding, design, and user
                        experience optimization.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main">
                    <CardBody>
                      <CardTitle className="csm-title">UI/UX Design</CardTitle>
                      <CardText className="csm-text">
                        ScriptCode specializes in providing top-tier design
                        services tailored to meet your needs. From captivating
                        logos that define your brand identity to intuitive user
                        interfaces (Static, Dynamic and eCommerce) that enhance
                        user experience, we craft visually stunning solutions
                        that leave a lasting impression.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="card-service-main2">
                    <CardBody>
                      <CardTitle className="csm-title">
                        Quality Assurance
                      </CardTitle>
                      <CardText className="csm-text">
                        We provide Quality Assurance (QA) services, to ensuring
                        that software products meet the highest standards of
                        performance, reliability, and user satisfaction. With a
                        meticulous approach and advanced testing methodologies,
                        we strive to identify and resolve any issues before they
                        impact end-users, guaranteeing a seamless experience and
                        optimal product quality.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main"
                  onClick={() => navigate("/mob-dev")}
                  >
                    <CardBody>
                      <CardTitle className="csm-title">
                        Mobile App Development
                      </CardTitle>
                      <CardText className="csm-text">
                        We excel in crafting mobile app designs that are smooth,
                        lightning-fast, and incredibly efficient. Our focus is
                        on creating user-friendly experiences that set new
                        standards in mobile interface design.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="card-service-main2">
                    <CardBody>
                      <CardTitle className="csm-title">
                        Software Solutions for Industries
                      </CardTitle>
                      <CardText className="csm-text"></CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main">
                    <CardBody>
                      <CardTitle className="csm-title">IT Consulting</CardTitle>
                      <CardText className="csm-text">
                        ScriptCode specializes in providing top-tier design
                        services tailored to meet your needs. From captivating
                        logos that define your brand identity to intuitive user
                        interfaces (Static, Dynamic and eCommerce) that enhance
                        user experience, we craft visually stunning solutions
                        that leave a lasting impression.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="card-service-main2">
                    <CardBody>
                      <CardTitle className="csm-title">
                        DevOps As a Service
                      </CardTitle>
                      <CardText className="csm-text"></CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main">
                    <CardBody>
                      <CardTitle className="csm-title">
                        Graphics Design
                      </CardTitle>
                      <CardText className="csm-text"></CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="card-service-main2">
                    <CardBody>
                      <CardTitle className="csm-title">SEO Service</CardTitle>
                      <CardText className="csm-text">
                        ScriptCode team excels in enhancing online visibility,
                        increasing organic traffic, and optimizing search engine
                        rankings to help businesses thrive in the digital
                        landscape.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup className="cardgroup-service-main">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="card-service-main">
                    <CardBody>
                      <CardTitle className="csm-title">
                      Web Accessibility as a Service
                      </CardTitle>
                      <CardText className="csm-text">
                      ScriptCode team excels in enhancing online visibility,
                        increasing organic traffic, and optimizing search engine
                        rankings to help businesses thrive in the digital
                        landscape.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="card-service-main2">
                    <CardBody>
                      <CardTitle className="csm-title">DevOps As a Service </CardTitle>
                      <CardText className="csm-text">
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br /> <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
      <Technology_Experties/>
    </>
  );
};

export default Service;
