import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  DropdownButton,
  Button,
  Container,
  Nav,
  Navbar,
  Dropdown,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import SC_logo_lb from "../../src/img/SC_logo_lb.png";
import SC_logo_RB from "../../src/img/SC_logo_RB.png";
import "./New_Header.css";

function New_Header() {
  const expand = "md";
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const headerMenuClick = (menu) => {
    setActiveLink(menu);
    navigate(menu);
    setShowSidebar(false); // Close sidebar on navigation
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 50);
  };

  const handleMouseEnter = (link) => {
    if (link !== activeLink) {
      setHoveredLink(link);
    }
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const isServiceActive =
    location.pathname === "/service" ||
    location.pathname === "/mob-dev" ||
    location.pathname === "/web-dev";

  return (
    <>
      <Navbar
        sticky="top"
        key={expand}
        expand={expand}
        className={`mb-3 header-cont-bg ${isScrolled ? "scrolled" : ""}`}
      >
        <Container fluid className="header-height">
          <Row>
            <Col xs={2} md={2}>
              <Navbar.Brand href="#">
                <img
                  src={isScrolled ? SC_logo_lb : SC_logo_RB}
                  alt="logo"
                  className="logo"
                ></img>
              </Navbar.Brand>
            </Col>
            <Col xs={8} md={8}></Col>
            <Col xs={2} md={2}>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className={`navbar-toggler sidebar-header ${
                  isScrolled ? "scrolled-toggle" : ""
                }`}
                onClick={() => setShowSidebar(true)} // Open sidebar
              />
            </Col>
          </Row>
          <Navbar.Offcanvas
            show={showSidebar} // Control visibility
            onHide={() => setShowSidebar(false)} // Close sidebar on toggle
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            className={`sidebar-body nav-ofcn ${
              isScrolled ? "scrolled-sidebar" : ""
            }`}
          >
            <Offcanvas.Header closeButton className="sidebar-body-header ">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${expand}`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 d-none d-md-flex nav-top nav-left">
                <Nav.Link
                  className={`nav-link ${activeLink === "/" ? "active" : ""} ${
                    hoveredLink === "/" ? "hovered" : ""
                  }`}
                  onClick={() => headerMenuClick("/")}
                  onMouseEnter={() => handleMouseEnter("/")}
                  onMouseLeave={handleMouseLeave}
                >
                  About ScriptCode
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${isServiceActive ? "active" : ""} ${
                    hoveredLink === "/service" ? "hovered" : ""
                  }`}
                  onClick={() => headerMenuClick("/service")}
                  onMouseEnter={() => handleMouseEnter("/service")}
                  onMouseLeave={handleMouseLeave}
                >
                  Service
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${
                    activeLink === "/resources" ? "active" : ""
                  } ${hoveredLink === "/resources" ? "hovered" : ""}`}
                  onClick={() => headerMenuClick("/resources")}
                  onMouseEnter={() => handleMouseEnter("/resources")}
                  onMouseLeave={handleMouseLeave}
                >
                  Resources
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${
                    activeLink === "/industries" ? "active" : ""
                  } ${hoveredLink === "/industries" ? "hovered" : ""}`}
                  onClick={() => headerMenuClick("/industries")}
                  onMouseEnter={() => handleMouseEnter("/industries")}
                  onMouseLeave={handleMouseLeave}
                >
                  Industries
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${
                    activeLink === "/portfolio" ? "active" : ""
                  } ${hoveredLink === "/portfolio" ? "hovered" : ""}`}
                  onClick={() => headerMenuClick("/portfolio")}
                  onMouseEnter={() => handleMouseEnter("/portfolio")}
                  onMouseLeave={handleMouseLeave}
                >
                  Portfolio
                </Nav.Link>
                <Button
                  variant=""
                  size="sm"
                  className={`button-header ${
                    isScrolled ? "scrolled-button" : ""
                  }`}
                >
                  Get Estimate
                </Button>
              </Nav>

              {/* Mobile Header Menu Opened*/}
              <div className="d-block d-md-none overlay pt-2">
                <Nav.Link
                  className={`mob-nav ${
                    activeLink === "/" ? "active-mob-nav" : ""
                  }`}
                  onClick={() => headerMenuClick("/")}
                >
                  <i
                    className="bi bi-file-person-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  About ScriptCode
                </Nav.Link>
                <hr className="mob-nav-hr" />

                <Nav.Link
                  className={`mob-nav ${
                    activeLink === "/service" ||
                    activeLink === "/mob-dev" ||
                    activeLink === "/web-dev"
                      ? "active-mob-nav"
                      : ""
                  }`}
                  onClick={() => headerMenuClick("/service")}
                >
                  <i
                    className="bi bi-pc-display-horizontal"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Service
                </Nav.Link>
                <hr className="mob-nav-hr" />

                <Nav.Link
                  className={`mob-nav ${
                    activeLink === "/resources" ? "active-mob-nav" : ""
                  }`}
                  onClick={() => headerMenuClick("/resources")}
                >
                  <i
                    className="bi bi-houses-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Resources
                </Nav.Link>
                <hr className="mob-nav-hr" />

                <Nav.Link
                  className={`mob-nav ${
                    activeLink === "/industries" ? "active-mob-nav" : ""
                  }`}
                  onClick={() => headerMenuClick("/industries")}
                >
                  <i
                    className="bi bi-pc-display-horizontal"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Industries
                </Nav.Link>
                <hr className="mob-nav-hr" />

                <Nav.Link
                  className={`mob-nav ${
                    activeLink === "/portfolio" ? "active-mob-nav" : ""
                  }`}
                  onClick={() => headerMenuClick("/portfolio")}
                >
                  <i
                    className="bi bi-heart-pulse-fill"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Portfolio
                </Nav.Link>
                <hr className="mob-nav-hr" />
                <br />
                <br />
                <br />
                <br />

                <p
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    marginRight: "0px",
                  }}
                  className="mob-nav"
                >
                  support@scriptcode.in
                </p>
                <br />
                <a
                  href="https://www.facebook.com/share/W8oSQHUTRJJxAWdZ/?mibextid=qi2Omg"
                  target="_blank"
                  className="link-sm mob-nav"
                >
                  <i
                    class="bi bi-facebook"
                    style={{
                      marginLeft: "10px",
                      marginRight: "8px",
                      marginTop: "20px",
                    }}
                  ></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/scriptcode-innovation/"
                  target="_blank"
                  className="link-sm mob-nav"
                >
                  <i
                    class="bi bi-linkedin"
                    style={{ marginLeft: "8px", marginTop: "20px" }}
                  ></i>
                </a>
                <a
                  href="https://www.instagram.com/scriptcodeinn?igsh=MWE2d3dkY2tib3Z5eA=="
                  target="_blank"
                  className="link-sm mob-nav"
                >
                  <i
                    class="bi bi-instagram"
                    style={{ marginLeft: "14px", marginTop: "20px" }}
                  ></i>
                </a>
                {/* <a href="" target="_blank" className="link-sm mob-nav">
                  <i
                    class="bi bi-twitter-x"
                    style={{ marginLeft: "8px", marginTop: "20px" }}
                  ></i>
                </a> */}
                <br />
                <br />
              </div>
              {/* {Mobile Header menu closed} */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <br /> <hr />
        </Container>
      </Navbar>
    </>
  );
}

export default New_Header;
