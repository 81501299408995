import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  InputGroup,
  CardBody,
  CardGroup,
  Accordion,
} from "react-bootstrap";
// import "./Aboutus.css";
import "./Resource.css";
import "./Visitor.css";
import Header from "./Header";

const Resources = () => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (file) => {
    setSelectedFileName(file ? file.name : ""); // Update selectedFileName with file name or empty string
    // Handle file upload or any other operations here if needed
  };

  const handleInputChange = (e) => {
    setSelectedFileName(e.target.value); // Update selectedFileName with input value
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setSelectedFileName(""); // Clear selectedFileName when backspace or delete key is pressed
    }
  };
  return (
    <>
      <Container
      className="cont-resource"
      >
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="divspace" />
            <Breadcrumb>
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active2">
                Resources
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />

            <br />

            <Container>
              <Row>
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                  <div>
                    <Row>
                      <Col xs={10} md={10}>
                        <h4 className="res-ttl">
                          Request project <br /> estimate
                        </h4>
                        <p className="res-pr">
                          Get a quote for design and development, mobile app
                          creation, software testing or another type of IT
                          project.
                        </p>
                        <br />
                        <br />
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <input
                                  type="name"
                                  placeholder="Name*"
                                  className="res-fm-ctrl form-cont3"
                                />
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <input
                                  type="name"
                                  placeholder="Email*"
                                  className="res-fm-ctrl form-cont3"
                                />
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <input
                                  type="name"
                                  placeholder="Phone Number"
                                  className="res-fm-ctrl form-cont3"
                                />
                              </div>
                            </div>
                          </Form.Group>
                          
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <Form.Label
                                  className="res-fm-hding"
                                  aria-required="true"
                                >
                                  What services are you interested in?
                                </Form.Label>
                              </div>
                              <Row>
                                <Col xs={6} md={6}>
                                <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Web App</p>
                              </div>
                              <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">iOS App</p>
                              </div>
                              <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Android App</p>
                              </div>
                              <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Other</p>
                              </div>
                                </Col>
                                <Col xs={6} md={6}>
                                <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Design</p>
                              </div>
                              <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Server-side development</p>
                              </div>
                              <div className="checkbox-container">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox2"
                                />
                                <p className="checkbox-label">Quality Assurance </p>
                              </div>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <Form.Label
                                  className="res-fm-hding"
                                  aria-required="true"
                                >
                                  Expected Budget
                                </Form.Label>
                              </div>
                              <input
                                type="name"
                                // placeholder="Phone Number"
                                className="res-fm-ctrl form-cont3"
                              />
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="div7">
                              <div className="div8">
                                <Form.Label
                                  className="res-fm-hding"
                                  aria-required="true"
                                >
                                  Message*
                                </Form.Label>
                              </div>
                              <input
                                type="name"
                                // placeholder="Phone Number"
                                className="res-fm-ctrl form-cont3"
                              />
                            </div>
                          </Form.Group>
                          <br/> <br/>
                          <button varient="" className="res-fm-submit">Send Message</button>
                        </Form>
                      </Col>
                      <Col xs={2} md={2}></Col>
                    </Row>
                  </div>
                  <br />

                  <br className="d-none d-md-block" />
                  <br className="d-none d-md-block" />
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <Container className="div-d">
              <Row>
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                  <br />
                  <br />
                  <br />
                  <h4 className="res-ttl">What’s next</h4>
                  {/* <br/> */}
                  <p className="res-pr">
                    We will contact you so you could ask important questions
                    about the best way to bring your business idea to life.
                  </p>
                  <br/>
                  <div className="div7">
                    <Row>
                      <Col xs={6} md={6}>
                        <div className="res-container">
                          <i className="bi bi-file-check-fill res-icon-cl"></i>
                          <p className="res-pr2">
                            Sanity-check of the project requirements.
                          </p>
                        </div>
                        <br />
                        <div className="res-container">
                          <i class="bi bi-book-fill res-icon-cl"></i>
                          <p className="res-pr2">
                            Breakdown in releases (in the "agile" style)
                          </p>
                        </div>
                        <br />
                        <div className="res-container">
                          <i class="bi bi-journal-bookmark res-icon-cl"></i>
                          <p className="res-pr2">
                            Development plan for the first release
                          </p>
                        </div>
                      </Col>
                      <Col xs={6} md={6}>
                        <div className="res-container">
                          <i className="bi bi-inbox-fill res-icon-cl"></i>
                          <p className="res-pr2">
                            Sanity-check of the project requirements.
                          </p>
                        </div>
                        <br />
                        <div className="res-container">
                          <i class="bi bi-gear-fill res-icon-cl"></i>
                          <p className="res-pr2">
                            Ballpark cost and duration of the project
                          </p>
                        </div>
                        <br />
                        <div className="res-container">
                          <i class="bi bi-bar-chart-line-fill res-icon-cl"></i>
                          <p className="res-pr2">Detailed report</p>
                        </div>
                        <br />
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
    </>
  );
};

export default Resources;
