import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
} from "react-bootstrap";
import "./AU_chain.css";

const AU_chain = () => {
  return (
    <>
      <h4 className="pi-heading">Project Initiation</h4>
      <CardGroup className="au-cardgroup">
        <Row>
          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">1</span>
                      <span className="title-text">
                        Requirement acquisition
                      </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Have a meeting with the client to learn about their
                      objectives, needs, and project expectations. Make sure all
                      needs are specified and any doubts are cleared up.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b d-none d-md-block">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>{" "}
                      <span className="title-text">Project kickoff</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Arrange a kickoff meeting with the team to go over the
                      project's objectives, go over roles and duties, and set up
                      lines of communication and tools for cooperation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">2</span>{" "}
                      <span className="title-text">Scope definition</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Define the project's deliverables, features, and
                      functionality in the scope definition. Make sure the goals
                      of the project and the expectations of the client are in
                      line.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b d-block d-md-none">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>{" "}
                      <span className="title-text">Project kickoff</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Arrange a kickoff meeting with the team to go over the
                      project's objectives, go over roles and duties, and set up
                      lines of communication and tools for cooperation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardGroup>
      <br /> <br />
      <h4 className="pi-heading">Planning Phase </h4>
      <CardGroup className="au-cardgroup d-none d-md-block">
        <Row>
          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">2</span>{" "}
                      <span className="title-text">Team Assignment</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Assign members of the team duties and responsibilities in
                      accordance with their qualifications and experience. Make
                      sure the group has enough tools and resources to complete
                      the project successfully.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">1</span>
                      <span className="title-text">Create Project Plan</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Draft a thorough project plan that outlines the resources,
                      deadlines, dependencies, tasks, and milestones. To
                      coordinate tasks and monitor progress, use project
                      management software such as Jira or Asana.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>{" "}
                      <span className="title-text">Risk Assessment</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Arrange a kickoff meeting with the team to go over the
                      project's objectives, go over roles and duties, and set up
                      lines of communication and tools for cooperation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardGroup>
      <CardGroup className="au-cardgroup d-block d-md-none">
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">1</span>
                  <span className="title-text">Create Project Plan</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Draft a thorough project plan that outlines the resources,
                  deadlines, dependencies, tasks, and milestones. To coordinate
                  tasks and monitor progress, use project management software
                  such as Jira or Asana.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">2</span>
                  <span className="title-text">Team Assignment</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Assign members of the team duties and responsibilities in
                  accordance with their qualifications and experience. Make sure
                  the group has enough tools and resources to complete the
                  project successfully.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">3</span>
                  <span className="title-text">Risk Assessment</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Arrange a kickoff meeting with the team to go over the
                  project's objectives, go over roles and duties, and set up
                  lines of communication and tools for cooperation.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </CardGroup>
      <br /> <br />
      <h4 className="pi-heading">Execution Phase </h4>
      <CardGroup className="au-cardgroup d-none d-md-block">
        <Row>
          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">1</span>
                      <span className="title-text">Agile Sprint Planning</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Divide the project into manageable chunks, or sprints, and
                      schedule the tasks for each team member. To create sprint
                      goals and prioritize tasks, hold sprint planning meetings
                      regularly.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>{" "}
                      <span className="title-text">Quality Assurance</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Ensuring the functionality, performance, and dependability
                      of each deliverable requires extensive testing, which is
                      part of quality assurance. To speed up the testing
                      process, use automated testing whenever it is practical.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">2</span>{" "}
                      <span className="title-text">Development</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Start working on the items in the prioritized backlog. To
                      track development and resolve problems, apply agile
                      techniques like frequent sprint reviews, continuous
                      integration, and daily stand-up meetings.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">4</span>{" "}
                      <span className="title-text">
                        Collaboration with the client
                      </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Keep in constant contact with the client to share project
                      updates, solicit input, and swiftly resolve any problems
                      or concerns.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardGroup>
      <CardGroup className="au-cardgroup d-block d-md-none">
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">1</span>
                  <span className="title-text">Agile Sprint Planning</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Divide the project into manageable chunks, or sprints, and
                  schedule the tasks for each team member. To create sprint
                  goals and prioritize tasks, hold sprint planning meetings
                  regularly.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">2</span>
                  <span className="title-text">Development</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Start working on the items in the prioritized backlog. To
                  track development and resolve problems, apply agile techniques
                  like frequent sprint reviews, continuous integration, and
                  daily stand-up meetings.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">3</span>
                  <span className="title-text">Quality Assurance</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Ensuring the functionality, performance, and dependability of
                  each deliverable requires extensive testing, which is part of
                  quality assurance. To speed up the testing process, use
                  automated testing whenever it is practical.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">4</span>
                  <span className="title-text">
                    Collaboration with the client
                  </span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Keep in constant contact with the client to share project
                  updates, solicit input, and swiftly resolve any problems or
                  concerns.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </CardGroup>
      <br /> <br />
      <h4 className="pi-heading">Observation and Management</h4>
      <CardGroup className="au-cardgroup d-none d-md-block">
        <Row>
          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">2</span>{" "}
                      <span className="title-text">Quality Control</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Ensuring that deliverables satisfy the necessary standards
                      and specifications requires quality control, which
                      involves ongoing monitoring and evaluation of the
                      products' quality. Put quality control procedures into
                      place to fix any flaws or shortcomings found.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">4</span>{" "}
                      <span className="title-text">Change Management</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Use a structured change control procedure to oversee
                      modifications to the project's requirements, goals, or
                      scope. Before moving forward, evaluate the effects of
                      modifications on the timeline, money, and resources and
                      secure the client's permission.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">1</span>
                      <span className="title-text">Progress Tracking</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Monitor project progress against the planned schedule and
                      budget. Identify any deviations or delays and take
                      corrective actions as necessary to keep the project on
                      track.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>{" "}
                      <span className="title-text">
                        Collaboration with the client
                      </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Keep in constant contact with the client to share project
                      updates, solicit input, and swiftly resolve any problems
                      or concerns.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardGroup>
      <CardGroup className="au-cardgroup d-block d-md-none">
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">1</span>
                  <span className="title-text">Progress Tracking</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Monitor project progress against the planned schedule and
                  budget. Identify any deviations or delays and take corrective
                  actions as necessary to keep the project on track.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">2</span>
                  <span className="title-text">Quality Control</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Ensuring that deliverables satisfy the necessary standards and
                  specifications requires quality control, which involves
                  ongoing monitoring and evaluation of the products' quality.
                  Put quality control procedures into place to fix any flaws or
                  shortcomings found.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">3</span>
                  <span className="title-text">
                    Collaboration with the client
                  </span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Keep in constant contact with the client to share project
                  updates, solicit input, and swiftly resolve any problems or
                  concerns.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">4</span>
                  <span className="title-text">Change Management</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Use a structured change control procedure to oversee
                  modifications to the project's requirements, goals, or scope.
                  Before moving forward, evaluate the effects of modifications
                  on the timeline, money, and resources and secure the client's
                  permission.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </CardGroup>
      <br /> <br />
      <h4 className="pi-heading">Closure Phase</h4>
      <CardGroup className="au-cardgroup d-none d-md-block">
        <Row>
          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">1</span>
                      <span className="title-text">
                        Final Testing and Acceptance
                      </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Verify that all deliverables satisfy the acceptance
                      standards outlined in the project scope by conducting
                      final testing and validation on them. Get the client's
                      approval before closing the project.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">3</span>
                      <span className="title-text">Project Review</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Call a project review meeting to assess the project's
                      overall performance, pinpoint areas of success and room
                      for development, and record lessons learned for upcoming
                      initiatives.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-duel-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">5</span>{" "}
                      <span className="title-text">Support & Maintenance </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      While we design our software for easy maintenance by your
                      team, we also offer ongoing support and maintenance
                      services for more complex or large-scale projects.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} className="au-col">
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">2</span>{" "}
                      <span className="title-text">
                        Documentation and Handover
                      </span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Provide thorough documentation, such as training
                      materials, technical documentation, and user manuals. To
                      guarantee a seamless transition of the project to the
                      client or support staff, hold knowledge transfer seminars.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Card className="au-cards-single-b">
                  <Card.Body>
                    <Card.Title className="au-card-title">
                      <span className="titlenumber">4</span>{" "}
                      <span className="title-text">Client Satisfaction</span>
                    </Card.Title>
                    <Card.Text className="au-card-text">
                      Get the client's input regarding how happy they are with
                      the project's results and their entire collaboration
                      experience. Make sure the client is satisfied with the
                      project delivery by addressing any worries or difficulties
                      they may have mentioned.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardGroup>
      <CardGroup className="au-cardgroup d-block d-md-none">
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">1</span>
                  <span className="title-text">
                    Final Testing and Acceptance
                  </span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Verify that all deliverables satisfy the acceptance standards
                  outlined in the project scope by conducting final testing and
                  validation on them. Get the client's approval before closing
                  the project.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">2</span>
                  <span className="title-text">Documentation and Handover</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Provide thorough documentation, such as training materials,
                  technical documentation, and user manuals. To guarantee a
                  seamless transition of the project to the client or support
                  staff, hold knowledge transfer seminars.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">3</span>
                  <span className="title-text">Project Review</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Call a project review meeting to assess the project's overall
                  performance, pinpoint areas of success and room for
                  development, and record lessons learned for upcoming
                  initiatives.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">4</span>
                  <span className="title-text">Client Satisfaction</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  Get the client's input regarding how happy they are with the
                  project's results and their entire collaboration experience.
                  Make sure the client is satisfied with the project delivery by
                  addressing any worries or difficulties they may have
                  mentioned.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="au-cards-duel-b">
              <Card.Body>
                <Card.Title className="au-card-title">
                  <span className="titlenumber">5</span>
                  <span className="title-text">Support & Maintenance</span>
                </Card.Title>
                <Card.Text className="au-card-text">
                  While we design our software for easy maintenance by your
                  team, we also offer ongoing support and maintenance services
                  for more complex or large-scale projects.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </CardGroup>
      <br />
    </>
  );
};

export default AU_chain;
