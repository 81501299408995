import { useEffect, useRef } from 'react';
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Header from "./components/Header";
import AboutUs from "./components/about-us/AboutUs";
import Service from "./components/services/Service";
import Web_Dev from "./components/services/Web_Dev";
import Mob_Dev from "./components/services/Mob_Dev";
import Resources from "./components/Resources";
import Industries from "./components/Industries";
import Portfolio from "./components/Portfolio";
import './App.css';

function App() {
  const hasAlerted = useRef(false);

  useEffect(() => {
    if (!hasAlerted.current) {
      alert('This website is under development, you may experience bugs and incomplete features.');
      hasAlerted.current = true;
    }
  }, []);
  return (
    <>
    <Routes>
                <Route path="/" element={<Layout />}>
                <Route path="header" element={<Header />} />
                <Route path="/" element={<AboutUs />} />
                <Route path="/service" element={<Service />} />
                <Route path="/web-dev" element={<Web_Dev />} />
                <Route path="/mob-dev" element={<Mob_Dev />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/industries" element={<Industries />} />
                <Route path="/portfolio" element={<Portfolio />} />
                </Route>
            </Routes>
    </>
  );
}

export default App;
