import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
} from "react-bootstrap";
import "./Aboutus.css";

import Header from "../Header";
import AU_chain from "./AU_chain";

const AboutUs = () => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (file) => {
    setSelectedFileName(file ? file.name : ""); // Update selectedFileName with file name or empty string
    // Handle file upload or any other operations here if needed
  };

  const handleInputChange = (e) => {
    setSelectedFileName(e.target.value); // Update selectedFileName with input value
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setSelectedFileName(""); // Clear selectedFileName when backspace or delete key is pressed
    }
  };
  return (
    <>
      <Container className="cont-bg">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="divspace" />
            <Breadcrumb>
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active">
                About ScriptCode
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <div>
              <Row>
                {/* <Col xs={12} md={2} /> */}
                <Col xs={12} md={12}>
                  <h3 className="heading-bold">About Us</h3>
                  <br />
                  <p className="para-bold">
                    Our passion at ScriptCode is developing innovative web
                    applications that enable companies to prosper in the digital
                    era. Focusing on innovation, dependability, and client
                    satisfaction, we provide custom solutions to fit every
                    project's particular requirements.
                    <br /> <br />
                    Our goal is to assist our clients in successfully and
                    efficiently achieving their business objectives by utilizing
                    the newest technology and industry best practices in web
                    application development. Our goal is to surpass client
                    expectations by providing scalable, high-quality solutions
                    that promote expansion and success.
                    <br /> <br />
                    We’ve got expertise in a range of industries: Healthcare,
                    Education, Real Estate, eCommerce, Retail, Fintech,
                    Insurance, Transportation, Travel, Sports, and much more.
                  </p>
                </Col>
                {/* <Col xs={12} md={2} /> */}
              </Row>
            </div>

            <br />
            <h5 className="heading2">How we work</h5>
            <br />
            <p className="para2">
              We follow the agile development methodology and we are open to
              changes and new ideas from your side at any stage of the
              development.
            </p>
            <br />
            <CardGroup>
              <Row className="card-group-row">
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">Knowledge</Card.Title>
                      <Card.Text className="card-txt1">
                        We can confidently and precisely take on a variety of
                        projects thanks to the broad range of technologies,
                        frameworks, and programming languages that our team of
                        talented engineers is proficient in.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">
                        Custom Solutions
                      </Card.Title>
                      <Card.Text className="card-txt1">
                        We tackle each job individually because we recognize
                        that every business is different. We collaborate closely
                        with our clients to create custom solutions that support
                        their goals and objectives, whether we're creating a new
                        application or improving an old one.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">
                        All our talents at your service
                      </Card.Title>
                      <Card.Text className="card-txt1">
                        The development team will provide you full-cycle
                        development services or get started at any project stage
                        with as many experts as you need.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup>
              <Row className="card-group-row">
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">
                        Assurance of Quality
                      </Card.Title>
                      <Card.Text className="card-txt1">
                        Our entire operation is centered around quality. We use
                        strict quality control procedures to guarantee the
                        stability, dependability, and bug-free nature of our
                        products. We are dedicated to quality from the very
                        beginning of the development process to the very
                        end—final delivery.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">
                        On-Time Delivery
                      </Card.Title>
                      <Card.Text className="card-txt1">
                        We recognize the value of adhering to deadlines and make
                        every effort to complete projects on schedule. We can
                        respond rapidly to changing needs and deliver results
                        swiftly without sacrificing quality thanks to our agile
                        development style.{" "}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="card-a">
                    <Card.Body>
                      <Card.Title className="card-title1">
                        Client-Centric Approach
                      </Card.Title>
                      <Card.Text className="card-txt1">
                        Our main goal is establishing enduring, solid
                        connections with our clients. Throughout the development
                        process, we place a high value on cooperation, open
                        communication, and openness to make sure that our
                        clients are constantly aware and participating.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br />

            <Container>
              <Row className="p-0 m-0">
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                  <b className="heading-cont2-top">How it goes, step by step</b>
                  <p className="para-top">
                    We use a traditional software development lifecycle in order
                    to keep the process open, transparent and give you a clear
                    road map to the successful conclusion of your project
                  </p>
                  <br />
                  <AU_chain />
                  <br /> <br />
                  <CardGroup className="cg-te">
                    <Row>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-n">
                          <Card.Body>
                            <Card.Title className="card-title2">
                              Technology Expertise
                            </Card.Title>
                            <Card.Text className="card-txt1"></Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className=" card-a">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Front end
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              HTML5, CSS, JavaScript, React, Angular.js
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Mobile Platforms
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              Android (Java), iOS, React Native
                            </Card.Text>
                            <Card.Title className="card-title3">API</Card.Title>
                            <Card.Text className="card-txt3">
                              Payment Gateways, Mail Services
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-a">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Back end & Servers
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              Java, Python, Node.js
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Database Management
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              PostgreSQL, MySQL, MongoDB
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Cloud Platforms
                            </Card.Title>
                            <Card.Text className="card-txt3">AWS</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </CardGroup>
                  <CardGroup className="cg-te">
                    <Row>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-n card-n2">
                          <Card.Body>
                            <Card.Title className="card-title2">
                              Other Technology Expertise
                            </Card.Title>
                            <Card.Text className="card-txt1"></Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-a card-x">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Third Party CMS
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              WordPress, Wix, Shopify
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        {/* <Card className="card-a card-n">
                          <Card.Body className="invisible-card"></Card.Body>
                        </Card> */}
                      </Col>
                    </Row>
                  </CardGroup>
                  <br />
                  <br />
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <Container className="div-b">
              <Row>
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                  <CardGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <Card className="card-c">
                          <Card.Body>
                            <Card.Title className="card-title-c">
                              Contact us
                            </Card.Title>
                            <Card.Text className="card-txt-c">
                              Got an idea for a project? Do you require
                              professional guidance or help with web
                              development? Please do not hesitate to contact.
                              Our committed team of experts is available to
                              assist you in realizing your concepts and
                              accomplishing your company objectives. Please
                              don't hesitate to get in touch with us by fill up
                              the form. We're eager to speak with you and talk
                              about how we can work together to make your vision
                              a reality.
                            </Card.Text>
                            <Card.Text className="card-txt-d">
                              Send us your request for proposal, and we’ll reply
                              with the estimate.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={6}>
                        <Card className="card-c">
                          <Card.Body>
                            <Form className="contact-us-form-pos">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Your Name*
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="name"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Email address*
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="email"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="false"
                                    >
                                      Phone
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="phonenumber"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Description"
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="phonenumber"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                                <br />
                                <div className="file-upload-cont">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                  <div className="div5">
                                    <Row className="file-upload-row">
                                      <Col
                                        xs={6}
                                        md={6}
                                        className="file-upload-col"
                                      >
                                        <input
                                          type="text"
                                          value={selectedFileName}
                                          placeholder="Choose a file"
                                          className="atu-fm-ctrl form-cont p-0 m-0"
                                          readOnly
                                          onChange={handleInputChange}
                                          onKeyDown={handleKeyDown} // Handle key down event
                                        />
                                      </Col>
                                      <Col
                                        xs={6}
                                        md={6}
                                        className="file-upload-col"
                                      >
                                        <button
                                          type="button"
                                          varient=""
                                          className="btn btn-primary ms-2 button-file"
                                          onClick={() =>
                                            document
                                              .getElementById("fileInput")
                                              .click()
                                          }
                                        >
                                          <i class="bi bi-file-earmark-arrow-up-fill"></i> &nbsp;
                                          Attach Files
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Form.Group>

                              <div className="div6">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox"
                                />
                                <p className="checkbox-txt">
                                  Sign me up to newsletter. Useful insights only
                                  and frequency just right.
                                </p>
                              </div>
                              <button varient="" className="button-wide-cu">
                                {" "}
                                Send
                              </button>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </CardGroup>
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
