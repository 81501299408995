import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
} from "react-bootstrap";
import "./Aboutus.css";
import AU_chain from "./AU_chain";

const AboutUs = () => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (file) => {
    setSelectedFileName(file ? file.name : ""); // Update selectedFileName with file name or empty string
    // Handle file upload or any other operations here if needed
  };

  const handleInputChange = (e) => {
    setSelectedFileName(e.target.value); // Update selectedFileName with input value
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setSelectedFileName(""); // Clear selectedFileName when backspace or delete key is pressed
    }
  };
  return (
    <>
      <Container
      // className="cont-bg"
      >
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>

          <Container>
              <Row>
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                  <br /> <br />
                  <CardGroup className="cg-te">
                    <Row>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-n">
                          <Card.Body>
                            <Card.Title className="card-title2">
                              Technology Expertise
                            </Card.Title>
                            <Card.Text className="card-txt1"></Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className=" card-a">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Front end
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              HTML5, CSS, JavaScript, React, Angular.js
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Mobile Platforms
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              Android (Java), iOS, React Native
                            </Card.Text>
                            <Card.Title className="card-title3">API</Card.Title>
                            <Card.Text className="card-txt3">
                              Payment Gateways, Mail Services
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-a">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Back end & Servers
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              Java, Python, Node.js
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Database Management
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              PostgreSQL, MySQL, MongoDB
                            </Card.Text>
                            <Card.Title className="card-title3">
                              Cloud Platforms
                            </Card.Title>
                            <Card.Text className="card-txt3">AWS</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </CardGroup>
                  <CardGroup className="cg-te">
                    <Row>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-n card-n2">
                          <Card.Body>
                            <Card.Title className="card-title2">
                              Other Technology Expertise
                            </Card.Title>
                            <Card.Text className="card-txt1"></Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                        <Card className="card-a card-x">
                          <Card.Body>
                            <Card.Title className="card-title3">
                              Third Party CMS
                            </Card.Title>
                            <Card.Text className="card-txt3">
                              WordPress, Wix, Shopify
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={4} className="d-flex">
                      </Col>
                    </Row>
                  </CardGroup>
                  <br />
                  <br /> <br/>
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <Container className="t-e-divb">
              <Row>
                <Col xs={12} md={1} />
                <Col xs={12} md={10}>
                <CardGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <Card className="card-c">
                          <Card.Body>
                            <Card.Title className="card-title-c">
                              Contact us
                            </Card.Title>
                            <Card.Text className="card-txt-c">
                              Got an idea for a project? Do you require
                              professional guidance or help with web
                              development? Please do not hesitate to contact.
                              Our committed team of experts is available to
                              assist you in realizing your concepts and
                              accomplishing your company objectives. Please
                              don't hesitate to get in touch with us by fill up
                              the form. We're eager to speak with you and talk
                              about how we can work together to make your vision
                              a reality.
                            </Card.Text>
                            <Card.Text className="card-txt-d">
                              Send us your request for proposal, and we’ll reply
                              with the estimate.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={6}>
                        <Card className="card-c">
                          <Card.Body>
                            <Form className="contact-us-form-pos">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Your Name*
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="name"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Email address*
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="email"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="false"
                                    >
                                      Phone
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="phonenumber"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div className="div3">
                                  <div className="div4">
                                    <Form.Label
                                      className="abt-fm-hding"
                                      aria-required="true"
                                    >
                                      Description"
                                    </Form.Label>
                                  </div>
                                  <input
                                    type="phonenumber"
                                    // placeholder="name@example.com"
                                    className="atu-fm-ctrl form-cont"
                                  />
                                </div>
                                <br />
                                <div className="file-upload-cont">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                  <div className="div5">
                                    <Row className="file-upload-row">
                                      <Col
                                        xs={6}
                                        md={6}
                                        className="file-upload-col"
                                      >
                                        <input
                                          type="text"
                                          value={selectedFileName}
                                          placeholder="Choose a file"
                                          className="atu-fm-ctrl form-cont p-0 m-0"
                                          readOnly
                                          onChange={handleInputChange}
                                          onKeyDown={handleKeyDown} // Handle key down event
                                        />
                                      </Col>
                                      <Col
                                        xs={6}
                                        md={6}
                                        className="file-upload-col"
                                      >
                                        <button
                                          type="button"
                                          varient=""
                                          className="btn btn-primary ms-2 button-file"
                                          onClick={() =>
                                            document
                                              .getElementById("fileInput")
                                              .click()
                                          }
                                        >
                                          <i class="bi bi-file-earmark-arrow-up-fill"></i> &nbsp;
                                          Attach Files
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Form.Group>

                              <div className="div6">
                                <Form.Check
                                  aria-label="option 1"
                                  className="checkbox"
                                />
                                <p className="checkbox-txt">
                                  Sign me up to newsletter. Useful insights only
                                  and frequency just right.
                                </p>
                              </div>
                              <button varient="" className="button-wide-cu">
                                {" "}
                                Send
                              </button>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </CardGroup>
                </Col>
                <Col xs={12} md={1} />
              </Row>
            </Container>
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
