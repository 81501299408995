import React, { useEffect, useState } from "react";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import "./Industries.css";
import "./Visitor.css";

const Portfolio = () => {
  const [animatedText, setAnimatedText] = useState("");
  
  useEffect(() => {
    const text = "This page is under development, It will open soon. Stay tuned !!!";
    let currentText = "";

    const animateText = (index) => {
      if (index < text.length) {
        currentText += text[index];
        setAnimatedText(currentText);
        setTimeout(() => animateText(index + 1), 100); // Adjust speed as desired
      }
    };

    animateText(0);

    return () => {}; // No need to clear timeout
  }, []);

  return (
    <>
      <Container className="cont-industries">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="divspace" />
            <Breadcrumb>
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active2">
                Portfolio
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <br />
            <br />

            <p className="animated-paragraph">{animatedText}</p>

            <br />
            <br />
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
      </Container>
    </>
  );
};

export default Portfolio;
