import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Card,
  Collapse,
  CardBody,
  CardGroup,
  Accordion,
  CardTitle,
  CardText,
} from "react-bootstrap";
import mob_dev from "../../img/mob_dev.png";
import "./Mob_Dev.css";
// import "./Web_Dev.css";

const Mob_Dev = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container className="m-d-cont">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="divspace" />
            <Breadcrumb>
              <Breadcrumb.Item active className="breadcrumb-inactive">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
                className="breadcrumb-active3"
                onClick={() => navigate("/service")}
              >
                Services
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="breadcrumb-active">
                Mobile Development
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />

            <CardGroup>
              <Row>
                <Col xs={12} md={6}>
                  <Card className="m-d-side-card">
                    <CardBody>
                      <CardTitle className="m-d-side-card-title">
                        Mobile <br />
                        Development <br />
                        Services <br />
                      </CardTitle>
                      <CardText className="m-d-side-card-text">
                        Transforming your mobile aspirations into reality, our
                        expert development team crafts bespoke solutions
                        tailored to your unique needs. With a keen eye on the
                        latest trends and technologies, we ensure your app
                        stands out in the crowded digital landscape.
                      </CardText>
                      <Button variant="" className="m-d-side-card-button">
                        Request a free quote
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="m-d-side-img-card">
                    <img
                      src={mob_dev}
                      alt="Mobile Development Image"
                      className="m-d-side-img"
                    />
                  </Card>
                </Col>
              </Row>
            </CardGroup>
          </Col>
          <Col xs={12} md={1} />
        </Row>

        <br />
        <br />
        <br />
      </Container>

      <Container className="m-d-cont2">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="m-dev-c2-div">
              <b className="m-dev-c2-title">
                Browse ScriptCode capabilities. Find the right match for your
                needs
              </b>
              <p className="m-dev-c2-text">
                Unlock the Power of Mobile Innovation: Elevate Your Business
                with Our Expert Development Solutions!
              </p>
            </div>
            <CardGroup className="m-dev-c2-cg">
              <Row>
                <Col xs={12} md={4} className="m-dev-c2-card-col">
                  <Card className="m-dev-c2-card">
                    <CardBody>
                      <CardTitle className="m-dev-c2-card-title">
                        Android development
                      </CardTitle>
                      <CardText className="m-dev-c2-card-text">
                        Empower your business with our comprehensive Android
                        development services. Our expert team leverages the
                        latest tools and technologies to craft innovative,
                        user-friendly applications tailored to your specific
                        needs. From concept to launch and beyond, we ensure
                        seamless integration, robust performance, and ongoing
                        support to drive your success in the ever-evolving
                        Android ecosystem.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={4} className="m-dev-c2-card-col">
                  <Card className="m-dev-c2-card">
                    <CardBody>
                      <CardTitle className="m-dev-c2-card-title">
                        iOS development
                      </CardTitle>
                      <CardText className="m-dev-c2-card-text">
                        Elevate your brand with our top-tier iOS development
                        services. Our seasoned team specializes in creating
                        sleek, high-performance applications that resonate with
                        Apple users. From intuitive design to flawless
                        functionality, we prioritize quality at every stage to
                        ensure your app stands out on the App Store. Partner
                        with us to turn your iOS app idea into a polished
                        reality that captivates audiences and drives results.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={4} className="m-dev-c2-card-col">
                  <Card className="m-dev-c2-card">
                    <CardBody>
                      <CardTitle className="m-dev-c2-card-title">
                        Hybrid development
                      </CardTitle>
                      <CardText className="m-dev-c2-card-text">
                        Supercharge your digital strategy with our hybrid app
                        development services. Combining the best of native and
                        web technologies, we create versatile applications that
                        run seamlessly across multiple platforms. Our expert
                        team ensures fast performance, consistent user
                        experience, and cost-effective solutions tailored to
                        your business needs. Partner with us to reach a wider
                        audience and maximize your app's potential.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup className="m-dev-c2-cg2">
              <Row>
                <Col xs={12} md={6} className="m-dev-c2-card-col-sp">
                  <Card className="m-dev-c2-card">
                    <CardBody>
                      <CardTitle className="m-dev-c2-card-title">
                        Progressive web apps
                      </CardTitle>
                      <CardText className="m-dev-c2-card-text">
                        Experience the future of web applications with our
                        progressive web app development services. Our skilled
                        team combines the best of web and mobile to create fast,
                        engaging, and reliable PWAs that work seamlessly across
                        all devices and platforms. From offline functionality to
                        push notifications, we leverage the latest technologies
                        to deliver exceptional user experiences. Join us in
                        revolutionizing the way users interact with your brand
                        online.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={6} className="m-dev-c2-card-col">
                  <Card className="m-dev-c2-card">
                    <CardBody>
                      <CardTitle className="m-dev-c2-card-title">
                        Cross-platform app development
                      </CardTitle>
                      <CardText className="m-dev-c2-card-text">
                        Expand your reach with our cross-platform app
                        development services. Our expert team utilizes
                        cutting-edge frameworks to build apps that seamlessly
                        operate across various platforms, ensuring maximum
                        exposure and user engagement. From streamlined
                        development processes to consistent user experiences, we
                        deliver tailored solutions that propel your business
                        forward in the digital landscape. Partner with us to
                        unleash the full potential of cross-platform
                        development.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
          </Col>
          <Col xs={12} md={1} />
        </Row>
        <br /> <br /> <br />
      </Container>

      <Container className="m-d-cont3">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="m-dev-c2-div">
              <b className="m-dev-c2-title">Explore more services</b>
              <p className="m-dev-c2-text">
                Unlocking Infinite Possibilities with ScriptCode's Mobile App
                Development Solutions. we cover every aspect of app development.
              </p>
            </div>
            <CardGroup className="m-dev-c2-cg2">
              <Row>
                <Col xs={12} md={6} className="m-dev-c2-card-col-sp">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Mobile App design
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        Discover innovative mobile app design solutions tailored
                        to your business needs. Our expert team combines
                        creativity and technical prowess to deliver visually
                        appealing and user-friendly applications that leave a
                        lasting impression. From wireframing to prototyping, we
                        guide you through every step of the design process,
                        ensuring a seamless experience from concept to launch.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={6} className="m-dev-c2-card-col">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Mobile Consulting
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        Our mobile consulting service provides expert guidance
                        to ensure your app is compatible with the latest
                        technologies and platforms. Our experienced developers
                        will assist you in optimizing performance, enhancing
                        user experience, and integrating cutting-edge features.
                        Trust us to navigate the complexities of mobile
                        development, so you can focus on delivering exceptional
                        apps.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup className="m-dev-c2-cg2">
              <Row>
                <Col xs={12} md={8} className="m-dev-c2-card-col-sp">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Mobile Backend
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        ScriptCode's Mobile Backend service provides a robust
                        and scalable solution for developers to build and manage
                        the server-side infrastructure of their mobile
                        applications. Whether you're developing an e-commerce
                        platform, educational app, food delivery service, or any
                        other type of mobile application, ScriptCode's Mobile
                        Backend service offers seamless integration with various
                        front-end technologies, ensuring optimal performance and
                        reliability. Key features include user authentication,
                        real-time database management, cloud storage, and push
                        notifications, all designed to help you deliver a smooth
                        and efficient user experience. With ScriptCode, focus on
                        creating exceptional mobile apps while leaving the
                        backend complexities to us.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={4} className="m-dev-c2-card-col">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Mobile Testing
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        Optimize your ROI and minimize uninstall rates. Our QA
                        team meticulously tests your mobile app, identifying
                        performance bottlenecks and addressing potential bugs
                        before they impact user experience. With our
                        comprehensive testing approach, you can ensure your app
                        meets the highest standards of quality and reliability.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup className="m-dev-c2-cg2">
              <Row>
                <Col xs={12} md={6} className="m-dev-c2-card-col-sp">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Third-Party Services Integration
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        ScriptCode Mobile Development Service offers seamless
                        integration of various external functionalities into
                        your mobile app. Our team of mobile development experts
                        ensures that integrations with services like Google
                        Maps, payment systems, social platforms, or any other
                        third-party service are finely tuned through APIs. By
                        leveraging our expertise, you can enhance your app's
                        functionality and provide users with a rich and cohesive
                        experience.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={6} className="m-dev-c2-card-col">
                  <Card className="m-dev-c3-card">
                    <CardBody>
                      <CardTitle className="m-dev-c3-card-title">
                        Accessibility for Mobile Apps
                      </CardTitle>
                      <CardText className="m-dev-c3-card-text">
                        ScriptCode team ensures your app complies with all
                        accessibility requirements, including contrast ratio,
                        attributed accessibility labels, and landscape
                        orientation. Our team of mobile app developers, UX/UI
                        designers, and testers will work together to enhance
                        usability and inclusivity, providing an optimal
                        experience for all users.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
          </Col>
          <Col xs={12} md={1} />
        </Row>
        <br /> <br /> <br />
      </Container>

      <Container className="m-d-cont4">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="m-d-cont4-div">
              <b className="m-d-cont4-title">
                Technology Stack for Mobile Development
              </b>
            </div>
            <CardGroup className="m-dev-c2-cg">
              <Row>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c5-card">
                    <CardBody>
                      <CardTitle className="m-dev-c5-card-title">
                        iOS App Development
                      </CardTitle>
                      <CardText className="m-dev-c5-card-text-sp">
                        {" "}
                        Swift <br /> <br /> SwiftUI
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c5-card">
                    <CardBody>
                      <CardTitle className="m-dev-c5-card-title">
                        Android App Development
                      </CardTitle>
                      <CardText className="m-dev-c5-card-text">
                        Kotlin <br /> <br /> JetPack <br /> <br /> Java
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c5-card">
                    <CardBody>
                      <CardTitle className="m-dev-c5-card-title">
                        Cross-platform
                      </CardTitle>
                      <CardText className="m-dev-c5-card-text">
                        {" "}
                        <br /> React Native <br /> <br /> JavaScript <br />{" "}
                        <br /> Flutter
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c5-card">
                    <CardBody>
                      <CardTitle className="m-dev-c5-card-title">
                        Progressive Development
                      </CardTitle>
                      <CardText className="m-dev-c5-card-text">
                        ReactJS <br /> <br /> Angular <br /> <br /> Redux
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br /> <br />
            <b className="m-dev-c5-title2">
              Specified Industry-Centric Mobile App Development
            </b>
            <p className="m-dev-c5-text2">
              ScriptCode provides mobile app development services for various
              industries such as healthcare, e-commerce, education, sports,
              social networking, and more. Our experienced developers bring
              their expertise to each project, ensuring that your app meets
              industry standards and delivers the functionality and user
              experience your users expect. Whether you're looking to
              revolutionize healthcare delivery, streamline e-commerce
              transactions, enhance learning experiences, engage sports
              enthusiasts, or connect people through social networking,
              ScriptCode has the skills and knowledge to turn your ideas into
              reality.
            </p>
            <br />
            <CardGroup className="m-dev-c2-cg">
              <Row>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c6-card">
                    <CardBody>
                      <CardTitle className="m-dev-c6-card-title">
                        eLearning Apps
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c6-card2">
                    <CardBody>
                      <CardTitle className="m-dev-c6-card-title">
                        Healthcare
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c6-card">
                    <CardBody>
                      <CardTitle className="m-dev-c6-card-title">
                        Real Estate
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className="m-dev-c2-card-col">
                  <Card className="m-dev-c6-card2">
                    <CardBody>
                      <CardTitle className="m-dev-c6-card-title">
                        Sports
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
          </Col>
          <Col xs={12} md={1} />
        </Row>
        <br /> <br /> <br />
      </Container>

      <Container className="m-d-cont5">
        <Row>
          <Col xs={12} md={1} />
          <Col xs={12} md={10}>
            <div className="m-d-cont5-div">
              <div className="m-d-cont5-title">
                <span>Got</span>&nbsp;
                <span>an</span>&nbsp;
                <span>app</span>&nbsp;
                <span>idea?</span>
              </div>

              <b className="m-d-cont5-title2">Let’s talk details!</b>
              <Button variant="" className="m-d-cont5-button">
                Book a call
              </Button>
            </div>

            <br/> <br/> 
            {/* web-dev section repeat */}

            <p className="md-cont6-title">
              Expectations from a Professional Mobile App Development Company
            </p>
            <p className="md-cont6-text">
              A web development company is crucial for driving your business's
              success in a highly competitive market. Skilled web development
              unlocks myriad advantages, such as effortlessly embracing the
              latest technologies and tailoring them to your distinct needs.
            </p>
            <br /> <br/> <br/>
            <CardGroup className="md-cont6-gp">
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">1</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Consultation and Strategy Development
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        We offer detailed discussions to understand your vision,
                        goals, and target audience, along with market research
                        and strategic planning to position your app effectively.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">2</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Design and Prototyping
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        We craft intuitive and user-friendly interfaces, create
                        visually appealing and brand-consistent designs, and
                        develop interactive prototypes to validate design
                        concepts and gather feedback.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">3</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Development
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        Our services include building the client-side interface,
                        developing the server-side logic, databases, and
                        integrations, creating application programming
                        interfaces for seamless connectivity with other
                        services, and ensuring the code is well-structured,
                        maintainable, and adheres to industry best practices.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>

            <CardGroup className="md-cont6-gp">
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">4</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Testing and Quality Assurance
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        We conduct rigorous manual and automated testing to
                        identify and fix bugs, perform performance testing to
                        ensure the app performs well under various conditions,
                        implement measures to protect against data breaches and
                        vulnerabilities, and provide a thorough quality check to
                        guarantee the app meets all specified requirements and
                        standards.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">5</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Deployment
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        We handle the submission process to app stores (Apple
                        App Store, Google Play Store), conduct beta tests with
                        real users to gather final feedback before the official
                        release, and provide support during the initial launch
                        phase to address any unforeseen issues.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card className="md-cont6-card">
                    <Card.Body>
                      <Card.Title className="md-c7-card-title">6</Card.Title>
                      <Card.Text className="md-c7-card-text1">
                        Post-Launch Services
                      </Card.Text>
                      <Card.Text className="md-c7-card-text2">
                        Our post-launch services include offering ongoing
                        maintenance to fix bugs, update libraries, and ensure
                        compatibility with new OS versions, developing and
                        integrating new features based on user feedback and
                        evolving business needs, and providing insights on app
                        performance, user engagement, and other key metrics to
                        inform future decisions.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br/>
            <br />
            <br />
            <br />
          </Col>
          <Col xs={12} md={1} />
        </Row>
        <br /> <br />
      </Container>
    </>
  );
};

export default Mob_Dev;
